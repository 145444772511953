import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@mui/material/CssBaseline';
import Page from './routes/Page';
import Home from './routes/Home';
import Search from './routes/Search';
import Doctor from './routes/Doctor';
import { urlBaseName } from './config';
import { useEffect } from 'react';
import { DoctorListProvider } from './store/DoctorListContext';

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Page />,
      children: [
        {
          path: '/',
          element: <Home />,
        },

        {
          path: '/search',
          element: <Search />,
        },
        {
          path: '/doctor/:doctorId',
          element: <Doctor />,
        },
      ],
    },
  ],
  { basename: urlBaseName },
);

function Router() {
  return <RouterProvider router={router} />;
}

function App() {
  useEffect(() => {
    return () => {
      localStorage.clear();
    };
  }, []);

  return (
    <SnackbarProvider maxSnack={3}>
      <CssBaseline />
      <DoctorListProvider>
        <Router />
      </DoctorListProvider>
    </SnackbarProvider>
  );
}

export default App;
