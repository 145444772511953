const ORIGIN = 'https://prms.dogalzeka.com.tr/v1';

export const baseURL = ORIGIN;
export const apiURL = ORIGIN + '/api/public';
export const authURL = ORIGIN + '/auth';

export const urlBaseName = '';
export const DR_HOST_ADDRESS = null;

export const GOOGLE_MAPS_API_KEY = 'AIzaSyB0qmvJGoSlVkCgHCmlfWtJfnDnp1IMsbE';

export const BUILD_LANGUAGE = 'en';
export const PRODUCT_NAME = BUILD_LANGUAGE === 'tr' ? 'PRMS' : 'DRFIND.EU';
