import { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Stack,
  Typography,
  Box,
  Rating,
  CircularProgress,
  Button,
  useMediaQuery,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { useSnackbar } from 'notistack';
import { axiosGet } from '../utils/utils';
import { apiURL, BUILD_LANGUAGE, GOOGLE_MAPS_API_KEY } from '../config';
import ProfileImage from '../assets/profile.jpg';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import bgImage from '../assets/background-gradient.jpg';
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import {
  MdHome,
  MdOutlinePerson,
  MdHealthAndSafety,
  MdWhatsapp,
} from 'react-icons/md';
import { FaCommentMedical } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { t } from '../utils/Language';
import { Helmet } from 'react-helmet';

const useStyles = createUseStyles({
  container: {
    width: '100%',
    minHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#E5E6FA',
    paddingTop: 20,
    paddingBottom: 10,
    zIndex: 10,
  },
  bgImg: {
    position: 'absolute',
    top: 0,
    width: '100vw',
    height: '100vh',
    objectFit: 'cover',
    opacity: 0.4,
    zIndex: 0,
  },
  card: {
    width: '70vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 10,
    padding: 10,
    zIndex: 1,
  },
  avatarCard: {
    width: '100%',
    flexDirection: 'column',
  },
  avatarCardRow0: {
    flexDirection: 'row',
  },
  imageContainer: {
    padding: 16,
  },
  image: {
    borderRadius: 3,
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    width: 300,
    height: 300,
    objectFit: 'cover',
  },
  avatarCardInfoPane: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  nameLine: { flexDirection: 'row' },
  avatarCardAddressPane: {
    marginTop: -40,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  addressContainer: { textAlign: 'right', marginRight: 20, maxWidth: 300 },
  mapContainer: {
    width: 240,
    height: 180,
  },
  googleMap: {
    position: 'absolute',
    top: 0,
    left: 0,
    paddingTop: 5,
    paddingBottom: 5,
    padding: 10,
    margin: 10,
    backgroundColor: 'white',
    textAlign: 'center',
  },
  showBigMap: {
    fontSize: 12,
    color: '#1a73e8',
  },
  fieldTitle: {
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 16,
  },
  fieldContent: { whiteSpace: 'pre-line' },

  '@media screen and (max-width: 1400px)': {
    card: {
      width: '80vw',
    },
  },
  '@media screen and (max-width: 1200px)': {
    nameLine: { flexDirection: 'column' },
    avatarCardAddressPane: {
      marginTop: 0,
    },
  },
  '@media screen and (max-width: 1080px)': {
    avatarCardInfoPane: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  },
  '@media screen and (max-width: 768px)': {
    card: {
      width: '90vw',
    },
    avatarCard: {
      flexDirection: 'column',
    },
    avatarCardRow0: {
      flexDirection: 'column',
    },
    imageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      marginRight: 0,
    },
    avatarCardAddressPane: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    addressContainer: { textAlign: 'left', marginRight: 0, maxWidth: 'unset' },
    mapContainer: {
      marginTop: 16,
    },
  },
});

function Doctor() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { doctorId } = useParams();
  const [doctor, setDoctor] = useState();
  const navigate = useNavigate();
  const [coordinates, setCoordinates] = useState();
  const [isHovered, setIsHovered] = useState(false);
  const lowWidth = useMediaQuery('(max-width:1120px)');

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    getDoctor();
    // eslint-disable-next-line
  }, []);

  const getDoctor = () => {
    axiosGet(`${apiURL}/doctors/${doctorId}?lang=${BUILD_LANGUAGE}`)
      .then(({ data }) => {
        setDoctor(data);
        getCoordinates(
          `${data.publicProfileInfo.city} ${data.publicProfileInfo.region} ${data.publicProfileInfo.address}`,
        );
      })
      .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }));
  };

  const getCoordinates = (address) => {
    axiosGet(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURI(
        address,
      )}&key=${GOOGLE_MAPS_API_KEY}`,
    )
      .then(({ data }) => {
        setCoordinates(data.results[0]?.geometry.location);
      })
      .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }));
  };

  const handleWhatsappClicked = () => {
    const message = `Merhaba ${doctor?.publicProfileInfo?.title ?? ''} ${
      doctor?.name
    } ${doctor?.surname}. `;

    window.open(
      `https://wa.me/${doctor.publicProfileInfo.phone}?text=${encodeURI(
        message,
      )}`,
      'mozillaTab',
    );
  };

  const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
      theme.palette.mode === 'light'
        ? theme.palette.grey[100]
        : theme.palette.grey[800];
    return {
      backgroundColor,
      height: theme.spacing(3),
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightRegular,
      '&:hover, &:focus': {
        backgroundColor: emphasize(backgroundColor, 0.06),
      },
      '&:active': {
        boxShadow: theme.shadows[1],
        backgroundColor: emphasize(backgroundColor, 0.12),
      },
      cursor: 'pointer',
    };
  });

  const addressPane = doctor ? (
    <Stack className={classes.avatarCardAddressPane}>
      <Box className={classes.addressContainer}>
        <Typography sx={{ fontWeight: 400, fontSize: 18 }}>
          {doctor.publicProfileInfo.institution}
        </Typography>
        <Typography style={{ whiteSpace: 'pre-line' }}>
          {doctor.publicProfileInfo.address}
        </Typography>
        <Typography>{`${
          doctor.publicProfileInfo.region
            ? `${doctor.publicProfileInfo.region}/`
            : ''
        }${doctor.publicProfileInfo.city}`}</Typography>
      </Box>
      <Box className={classes.mapContainer}>
        <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
          <Map
            clickableIcons={false}
            center={coordinates}
            zoom={10}
            style={{ position: 'relative' }}
            disableDefaultUI>
            <Marker position={coordinates} />
            <div className={classes.googleMap}>
              <a
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className={classes.showBigMap}
                style={{
                  textDecoration: isHovered ? 'underline' : 'none',
                }}
                href={`https://www.google.com/maps/search/?api=1&query=${coordinates?.lat}%2C${coordinates?.lng}`}
                target='_blank'
                rel='noreferrer'>
                {t('showBigMap')}
              </a>
            </div>
          </Map>
        </APIProvider>
      </Box>
    </Stack>
  ) : null;

  let keywords = '';
  if (doctor) {
    keywords = doctor.publicProfileInfo.specialties?.join(', ');
    keywords = keywords?.concat(`, ${doctor.name}, ${doctor.surname}`);
    keywords = keywords?.concat(`, ${doctor.proficiency}`);
    if (doctor.publicProfileInfo.institution)
      keywords = keywords?.concat(`, ${doctor.publicProfileInfo.institution}`);
    if (doctor.publicProfileInfo.city)
      keywords = keywords?.concat(`, ${doctor.publicProfileInfo.city}`);
    if (doctor.publicProfileInfo.region)
      keywords = keywords?.concat(`, ${doctor.publicProfileInfo.region}`);
    keywords = keywords?.concat(
      ', doctor, dr, PRMS, DRFIND, find a doctor, surgeon, surgery, medical, hospital, medicine',
    );
  }

  return (
    <div className={classes.container}>
      <img src={bgImage} className={classes.bgImg} alt='bg' />

      {!doctor && <CircularProgress />}

      {doctor && (
        <>
          <Helmet>
            <title>{`${doctor.publicProfileInfo.title ?? ''} ${doctor.name} ${
              doctor.surname
            }`}</title>
            <meta name='description' content={`${doctor.proficiency}`} />
            <meta name='keywords' content={keywords} />
            <meta
              property='og:title'
              content={`${doctor.publicProfileInfo.title ?? ''} ${
                doctor.name
              } ${doctor.surname}`}
            />
            <meta property='og:description' content={doctor.proficiency} />
            <meta
              property='og:image'
              content={doctor.thumbnailUrl ?? ProfileImage}
            />
          </Helmet>
          <Card className={classes.card}>
            <div role='presentation'>
              <Breadcrumbs aria-label='breadcrumb'>
                <StyledBreadcrumb
                  onClick={() => navigate('/')}
                  label={t('homepage')}
                  icon={<MdHome fontSize='small' />}
                />
                <StyledBreadcrumb
                  onClick={() => {
                    navigate('/search');
                  }}
                  icon={<MdHealthAndSafety fontSize='small' />}
                  label={
                    doctor.proficiency + ' - ' + doctor.publicProfileInfo.city
                  }
                />

                <StyledBreadcrumb
                  icon={<MdOutlinePerson fontSize='small' />}
                  label={doctor.name + ' ' + doctor.surname}
                />
              </Breadcrumbs>
            </div>
            <Stack className={classes.avatarCard}>
              <Stack className={classes.avatarCardRow0}>
                <div className={classes.imageContainer}>
                  <img
                    alt='Doktor profil fotografi'
                    className={classes.image}
                    src={doctor.thumbnailUrl ?? ProfileImage}
                  />
                </div>

                <div style={{ padding: 16, flex: 1 }}>
                  <Stack className={classes.avatarCardInfoPane}>
                    <Stack flexDirection='column'>
                      <Stack className={classes.nameLine}>
                        <Typography sx={{ fontWeight: 500, fontSize: 24 }}>
                          {`${doctor.publicProfileInfo.title ?? ''} ${
                            doctor.name
                          } ${doctor.surname}`}
                        </Typography>

                        <Rating
                          readOnly
                          value={doctor.publicProfileInfo?.rating}
                          sx={{ mt: 1 }}
                        />
                      </Stack>
                      <Typography sx={{ fontWeight: 400, fontSize: 20 }}>
                        {doctor.proficiency}
                      </Typography>
                      <Typography>{doctor.publicProfileInfo.phone}</Typography>
                      <Typography>{doctor.publicProfileInfo.email}</Typography>
                      <div>
                        <MdWhatsapp
                          style={{ cursor: 'pointer' }}
                          size={28}
                          color={'#25D366'}
                          onClick={handleWhatsappClicked}
                        />
                      </div>
                    </Stack>

                    <Button
                      variant='contained'
                      disabled
                      endIcon={<FaCommentMedical size={24} />}>
                      {t('inquiryRequest')}
                    </Button>
                  </Stack>
                  {!lowWidth && <div>{addressPane}</div>}
                </div>
              </Stack>

              {lowWidth && <div style={{ padding: 16 }}>{addressPane}</div>}
            </Stack>
          </Card>

          <Card className={classes.card}>
            <CardContent sx={{ width: '100%' }}>
              <Typography className={classes.fieldTitle}>
                {t('specialties')}
              </Typography>
              <Stack
                flexDirection='row'
                justifyContent='flex-start'
                flexWrap='wrap'>
                {doctor.publicProfileInfo.specialties?.map((s, idx) => (
                  <li key={`${s}_idx`} style={{ width: '50%' }}>
                    {s}
                  </li>
                ))}
              </Stack>
            </CardContent>
          </Card>

          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.fieldTitle}>
                {t('about')}
              </Typography>
              <Typography className={classes.fieldContent}>
                {doctor.publicProfileInfo.about}
              </Typography>
            </CardContent>
          </Card>

          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.fieldTitle}>
                {t('interests')}
              </Typography>
              <Typography className={classes.fieldContent}>
                {doctor.publicProfileInfo.interests}
              </Typography>
            </CardContent>
          </Card>

          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.fieldTitle}>
                {t('education')}
              </Typography>
              <Typography className={classes.fieldContent}>
                {doctor.publicProfileInfo.education}
              </Typography>
            </CardContent>
          </Card>

          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.fieldTitle}>
                {t('academic')}
              </Typography>
              <Typography className={classes.fieldContent}>
                {doctor.publicProfileInfo.academic}
              </Typography>
            </CardContent>
          </Card>
        </>
      )}
    </div>
  );
}

export default Doctor;
