import axios from 'axios';

const AXIOS_REQUEST_TIMEOUT = 5000;

export const axiosGet = (url, params) =>
  axios.get(url, {
    timeout: AXIOS_REQUEST_TIMEOUT,
    ...params,
  });

export const cities = [
  { id: 1, label: 'İSTANBUL' },
  { id: 2, label: 'ANKARA' },
  { id: 3, label: 'İZMİR' },
  { id: 4, label: 'ADANA' },
  { id: 5, label: 'ADIYAMAN' },
  { id: 6, label: 'AFYONKARAHİSAR' },
  { id: 7, label: 'AĞRI' },
  { id: 8, label: 'AMASYA' },
  { id: 9, label: 'ANTALYA' },
  { id: 10, label: 'ARTVİN' },
  { id: 11, label: 'AYDIN' },
  { id: 12, label: 'BALIKESİR' },
  { id: 13, label: 'BİLECİK' },
  { id: 14, label: 'BİNGÖL' },
  { id: 15, label: 'BİTLİS' },
  { id: 16, label: 'BOLU' },
  { id: 17, label: 'BURDUR' },
  { id: 18, label: 'BURSA' },
  { id: 19, label: 'ÇANAKKALE' },
  { id: 20, label: 'ÇANKIRI' },
  { id: 21, label: 'ÇORUM' },
  { id: 22, label: 'DENİZLİ' },
  { id: 23, label: 'DİYARBAKIR' },
  { id: 24, label: 'EDİRNE' },
  { id: 25, label: 'ELAZIĞ' },
  { id: 26, label: 'ERZİNCAN' },
  { id: 27, label: 'ERZURUM' },
  { id: 28, label: 'ESKİŞEHİR' },
  { id: 29, label: 'GAZİANTEP' },
  { id: 30, label: 'GİRESUN' },
  { id: 31, label: 'GÜMÜŞHANE' },
  { id: 32, label: 'HAKKARİ' },
  { id: 33, label: 'HATAY' },
  { id: 34, label: 'ISPARTA' },
  { id: 35, label: 'MERSİN' },
  { id: 36, label: 'KARS' },
  { id: 37, label: 'KASTAMONU' },
  { id: 38, label: 'KAYSERİ' },
  { id: 39, label: 'KIRKLARELİ' },
  { id: 40, label: 'KIRŞEHİR' },
  { id: 41, label: 'KOCAELİ' },
  { id: 42, label: 'KONYA' },
  { id: 43, label: 'KÜTAHYA' },
  { id: 44, label: 'MALATYA' },
  { id: 45, label: 'MANİSA' },
  { id: 46, label: 'KAHRAMANMARAŞ' },
  { id: 47, label: 'MARDİN' },
  { id: 48, label: 'MUĞLA' },
  { id: 49, label: 'MUŞ' },
  { id: 50, label: 'NEVŞEHİR' },
  { id: 51, label: 'NİĞDE' },
  { id: 52, label: 'ORDU' },
  { id: 53, label: 'RİZE' },
  { id: 54, label: 'SAKARYA' },
  { id: 55, label: 'SAMSUN' },
  { id: 56, label: 'SİİRT' },
  { id: 57, label: 'SİNOP' },
  { id: 58, label: 'SİVAS' },
  { id: 59, label: 'TEKİRDAĞ' },
  { id: 60, label: 'TOKAT' },
  { id: 61, label: 'TRABZON' },
  { id: 62, label: 'TUNCELİ' },
  { id: 63, label: 'ŞANLIURFA' },
  { id: 64, label: 'UŞAK' },
  { id: 65, label: 'VAN' },
  { id: 66, label: 'YOZGAT' },
  { id: 67, label: 'ZONGULDAK' },
  { id: 68, label: 'AKSARAY' },
  { id: 69, label: 'BAYBURT' },
  { id: 70, label: 'KARAMAN' },
  { id: 71, label: 'KIRIKKALE' },
  { id: 72, label: 'BATMAN' },
  { id: 73, label: 'ŞIRNAK' },
  { id: 74, label: 'BARTIN' },
  { id: 75, label: 'ARDAHAN' },
  { id: 76, label: 'IĞDIR' },
  { id: 77, label: 'YALOVA' },
  { id: 78, label: 'KARABüK' },
  { id: 79, label: 'KİLİS' },
  { id: 80, label: 'OSMANİYE' },
  { id: 81, label: 'DÜZCE' },
];
