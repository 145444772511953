import { createContext, useRef, useState, useCallback } from 'react';

export const DoctorListContext = createContext({
  doctorList: [],
  meta: { next: '' },
  searchData: { city: { label: '' }, proficiency: { id: null, label: '' } },
  yPos: 0,
  updateDoctorList: () => {},
  updateMeta: () => {},
  updateSearchData: () => {},
  updateYPos: () => {},
});

export const DoctorListProvider = ({ children }) => {
  const [doctorList, setDoctorList] = useState(null);
  const [meta, setMeta] = useState({ next: '' });
  const [searchData, setSearchData] = useState({
    city: null,
    proficiency: null,
  });
  const [yPos, setYPosition] = useState(null);

  const updateDoctorList = useCallback((data) => {
    setDoctorList(data);
  }, []);

  const updateMeta = useCallback((data) => {
    setMeta(data);
  }, []);

  const updateSearchData = useCallback((data) => {
    setSearchData(data);
  }, []);

  const updateYPos = useCallback((pos) => {
    setYPosition(pos);
  }, []);

  return (
    <DoctorListContext.Provider
      value={{
        doctorList,
        meta,
        searchData,
        yPos,
        updateDoctorList,
        updateMeta,
        updateSearchData,
        updateYPos,
      }}>
      {children}
    </DoctorListContext.Provider>
  );
};
