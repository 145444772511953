import { createUseStyles } from 'react-jss';
import Header from '../components/Header/Header';
import HowToUse from '../components/HowToUse/HowToUse';
import AboutUs from '../components/AboutUs/AboutUs';
import Contact from '../components/Contact/Contact';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { PRODUCT_NAME } from '../config';

const useStyles = createUseStyles({
  container: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function Home() {
  const classes = useStyles();

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div className={classes.container}>
      <Helmet>
        <title>{PRODUCT_NAME}</title>
      </Helmet>
      <Header />
      <HowToUse />
      <AboutUs />
      <Contact />
    </div>
  );
}

export default Home;
