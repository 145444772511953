import { BUILD_LANGUAGE } from '../config';

const localeStrings = {
  tr: {
    homepage: 'Anasayfa',
    howToUse: 'Nasıl Kullanırım',
    aboutUs: 'Hakkımızda',
    contact: 'İletişim',
    areYouDoctor: 'Doktor musunuz?',
    search: 'Doktor Ara',
    selectProficiency: 'Branş Seçiniz',
    selectCity: 'Şehir Seçiniz',
    noDoctorFound: 'İstediğiniz özelliklerde bir doktor bulunamadı',
    phone: 'Tel',
    email: 'E-mail',
    address: 'Adres',
    specialties: 'Uzmanlık Alanları',
    resume: 'Özgeçmiş',
    about: 'Hakkında',
    interests: 'Çalışma Alanları',
    education: 'Eğitim Bilgileri',
    academic: 'Akademik Çalışmalar',
    showBigMap: 'Haritayı görüntüle',
    kvkk: 'Gizlilik ve Kişisel Verilerin Korunması Politikası',
    inquiryRequest: 'Doktora danış',

    sliderHeader_1: 'Aradığınız Doktoru Bulun',
    sliderText_1:
      'İhtiyacınız olan branş ve tercih ettiğiniz şehirleri girerek her biri konularında uzman olan doktorlarımızı listeleyin.',
    sliderHeader_2: 'Uzman Olduğu Konuları İnceleyin',
    sliderText_2:
      'Listelenen doktorların uzmanlaşma konularını, eğitimlerini, tecrübelerini inceleyin, ihtiyaçlarınıza tam olarak uyan doktoru bulun.',
    sliderHeader_3: 'Değerlendirme Talebi Gönderin',
    sliderText_3:
      'Kişisel bilgilerinizi, şikayetlerinizi, isteklerinizi yazın, fotoğraflarınızı yükleyerek doktorunuz tarafından değerlendirilmek üzere bir talep gönderin (çok yakında).',
    sliderHeader_4: 'Doktorunuzla İrtibata Geçin',
    sliderText_4:
      'Doktorunuzun lokasyon ve iletişim bilgilerini görün, ister direkt olarak iletişime geçin, isterseniz Whatsapp üzerinden mesaj gönderin.',

    htu_item_1:
      'Kullanıcı yaratmanıza gerek yoktur. Kişisel bilgileriniz hiçbir şekilde kaydedilmez.',
    htu_item_2: 'Şehir ve uzmanlık alanı belirterek arama yapın.',
    htu_item_3: 'Listelenen doktorların profil bilgilerini inceleyin.',
    htu_item_4:
      'İhtiyaçlarınıza tam olarak cevap verebilecek doktoru tespit edin.',
    htu_item_5:
      'İletişim bilgilerini kullanarak direkt olarak doktorunuzla iletişime geçebilirsiniz.',
    htu_item_6:
      "Doktorunuza e-posta ya da Whatsapp'tan direkt mesaj gönderebilirsiniz.",
    htu_item_7:
      'Arzu ederseniz kişisel bilgilerinizi, şikayetlerinizi, isteklerinizi ve fotoğraflarınızı ekleyip doktorunuza talep gönderebilirsiniz (çok yakında).',
    htu_item_8:
      'Doktorunuz en kısa sürede durumunuzu değerlendirip size dönüş yapacaktır.',

    aboutUsDescription:
      "Doğalzeka, 2006 yılında kurulmuş, Internet üzerinden video akıtma ve uygulamaları üzerinde uzmanlaşmış, %100 yerli sermayeli bir teknoloji ve yazılım geliştirme şirketi olup AR-GE faaliyetlerine Yıldız Teknik Üniversitesi Teknopark'ta devam etmektedir.",
    contact: 'İletişim',
  },
  en: {
    homepage: 'Home',
    howToUse: 'How To Use',
    aboutUs: 'About Us',
    contact: 'Contact',
    areYouDoctor: 'Are you a doctor?',
    search: 'Search for a Doctor',
    selectProficiency: 'Select Proficiency',
    selectCity: 'Select City',
    noDoctorFound: 'Found no doctors matching your criteria',
    phone: 'Phone',
    email: 'E-mail',
    address: 'Address',
    specialties: 'Specialties',
    resume: 'Resume',
    about: 'About',
    interests: 'Areas of Interest',
    education: 'Education Information',
    academic: 'Academic Studies',
    showBigMap: 'View Map',
    kvkk: 'Privacy and Personal Data Protection Policy',
    inquiryRequest: 'Inquiry',

    sliderHeader_1: 'Search Our Team of Top Doctors',
    sliderText_1:
      'List our top notch doctors and surgeons by using the expertise area you seek and the city you prefer.',
    sliderHeader_2: "Explore Your Doctor's Expertise",
    sliderText_2:
      "Examine the listed doctors' specializations, training, experience and find the doctor that fits your needs exactly.",
    sliderHeader_3: 'Send an Enquiry To Your Doctor',
    sliderText_3:
      'Write your personal information, complaints, requests, upload your photos and send an enquiry to be evaluated by your doctor (coming soon).',
    sliderHeader_4: 'Contact Your Doctor Directly',
    sliderText_4:
      "See your doctor's location and contact information, contact him/her directly or send a message via Whatsapp.",

    htu_item_1:
      'You do not need to create a user. Your personal information is not recorded in any way.',
    htu_item_2:
      'Search among our database by city and the area of ​​expertise.',
    htu_item_3: 'View the profile information of the doctors.',
    htu_item_4: 'Identify the doctor who exactly fits your needs.',
    htu_item_5:
      'You can contact your doctor directly using the contact information provided.',
    htu_item_6: 'You can send an e-mail or a Whatsapp message to your doctor.',
    htu_item_7:
      'If you wish, you can add your personal information, complaints, requests, photos and send an enquiry to your doctor (coming soon).',
    htu_item_8:
      'Your doctor will evaluate your condition and get back to you as soon as possible.',

    aboutUsDescription:
      'Founded in 2006, Doğalzeka is a technology and software development company specializing in video streaming and applications over the Internet, with 100% domestic capital. The company continues its R&D activities at Yıldız Technical University Technopark.',
  },
};

const translate = (key) => {
  let value = localeStrings[BUILD_LANGUAGE][key];

  if (value === undefined) value = key;

  return value;
};

export { translate as t };
